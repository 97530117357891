// IE9, IE10 and IE11 requires all of the following polyfills
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

// IE10 and IE11 requires the following for NgClass support on SVG elements
import 'classlist.js';

// IE10 and IE11 requires the following to support @angular/animation
import 'web-animations-js';

// Evergreen browsers require these
import 'core-js/es6/reflect';
import 'core-js/es7/reflect';

// TODO: проверить, нужно ли это для поддержки ie11
import 'better-dom/dist/better-dom.min.js';
import 'better-i18n-plugin/dist/better-i18n-plugin.js';
import 'better-time-element/dist/better-time-element.js';
import 'better-dateinput-polyfill/dist/better-dateinput-polyfill.js';

import 'core-js/client/shim.min';

import 'shared/assets/lib/shims_for_IE.prod';
